#container {
	/* width: 1800px; */
	/* margin: auto; */
  /* border: 1px solid black; */
  margin-top: 100px;
  /* margin-bottom: 100px; */
  /* margin-right: 9500px; */
  margin-left: 80px;
  /* background-color: lightblue; */
  /* height: 800px; */
}
.full-width{
  width: 100%;
}
.m-1{
  margin: 1em;
}
.btn-theme {
  background: #333996;
  background-image: -webkit-linear-gradient(top, #333996, #232769);
  background-image: -moz-linear-gradient(top, #333996, #232769);
  background-image: -ms-linear-gradient(top, #333996, #232769);
  background-image: -o-linear-gradient(top, #333996, #232769);
  background-image: linear-gradient(to bottom, #333996, #232769);
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  color: #FFFFFF;
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 100;
  padding: 15px;
  box-shadow: 1px 1px 20px 0px #000000;
  -webkit-box-shadow: 1px 1px 20px 0px #000000;
  -moz-box-shadow: 1px 1px 20px 0px #000000;
  text-shadow: 1px 1px 20px #000000;
  border: solid #232769 1px;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  text-align: center;
  }
  
  .btn-theme:hover {
  border: solid #337FED 1px;
  background: #1E62D0;
  color:#FFFFFF;
  background-image: -webkit-linear-gradient(top, #1E62D0, #3D94F6);
  background-image: -moz-linear-gradient(top, #1E62D0, #3D94F6);
  background-image: -ms-linear-gradient(top, #1E62D0, #3D94F6);
  background-image: -o-linear-gradient(top, #1E62D0, #3D94F6);
  background-image: linear-gradient(to bottom, #1E62D0, #3D94F6);
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  text-decoration: none;
  }